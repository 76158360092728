


  const firebaseDevConfig = {
    apiKey: "AIzaSyAHvYfDGQPutKDFRuiPCVFfbgrhRYw3cuo",
    authDomain: "progressivedev-d975a.firebaseapp.com",
    databaseURL: "https://progressivedev-d975a.firebaseio.com",
    projectId: "progressivedev-d975a",
    storageBucket: "progressivedev-d975a.appspot.com",
    messagingSenderId: "204604274657",
    appId: "1:204604274657:web:67e91c6d580f8c208ec4a0",
    measurementId: "G-YH07F6RNRJ",
    supportUrl: 'https://us-central1-progressivedev-d975a.cloudfunctions.net/sendSupportRequest'
  };

  const firebaseConfig = {
    apiKey: "AIzaSyA_cLr6r8QxjnIGmhxc7-XHrRo7dsjT6Ks",
    authDomain: "muscular-d445e.firebaseapp.com",
    databaseURL: "https://muscular-d445e.firebaseio.com",
    projectId: "muscular-d445e",
    storageBucket: "muscular-d445e.appspot.com",
    messagingSenderId: "146013807542",
    appId: "1:146013807542:web:71d65c80af816681521717",
    measurementId: "G-LGE1HZ7VE0",
    supportUrl: 'https://us-central1-muscular-d445e.cloudfunctions.net/sendSupportRequest'
  };

  export default firebaseConfig