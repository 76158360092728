import React, { Component } from 'react'


export class NoMatch extends Component {
    
    
    render() {
        return (
            <div class="noMatch">
                <h1>Error 404</h1>
                <h2>Page not found!</h2>
            </div>
        )
    }
}



export default NoMatch