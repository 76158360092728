import React from 'react';
import {Jumbotron as Jumbo, Container} from 'react-bootstrap';

const Jumbotron = ()=> {

    return (
        <Jumbo fluid className="jumbo"  >
            <div className="overlay">
                <Container>
                    
                </Container>
            </div>
        </Jumbo>

    )

}



export default Jumbotron