import React from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import navLogo from '../Assets/muscularAppIcon.png';

const NavigationBar = () => {

    return (
        <Navbar expand='lg' bg='dark' variant='dark' sticky='top'>
            <Navbar.Brand href="/">
                <img
                    src={navLogo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Muscular app icon"
                />
            </Navbar.Brand>
            <Navbar.Brand href="/">Muscular App</Navbar.Brand>
            <Navbar.Toggle  aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-nabar-nav">
                <Nav className="mr-auto">
                     {/* bootstrap: Nav.Link and href=.. but entire page reloads. so use default Reactjs NavLink */}
                    <Nav.Item><NavLink exact to='/' className='navBarLink' > Home </NavLink> | </Nav.Item>
                    <Nav.Item><NavLink to='/terms' className='navBarLink'> Terms&Conditions </NavLink> | </Nav.Item>
                    <Nav.Item><NavLink to='/privacy' className='navBarLink'> Privacy Policy </NavLink> | </Nav.Item>
                    <Nav.Item><NavLink to='/support' className='navBarLink'> Support </NavLink> | </Nav.Item>
                </Nav>
            </Navbar.Collapse>

        </Navbar>
    )

}

// const navbarStyle = {
//    background: '#333'
// }

// const navbarElementsStyle = {
//     color: 'green'
// }



export default NavigationBar;