
import React, { Component } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Figure from 'react-bootstrap/Figure'

import AppIcon from '../Assets/muscularAppIcon.png'
import AppStoreIcon from '../Assets/downloadOnAppStoreBadge_black_en.png'

import WorkoutViewLight from '../Assets/workoutViewLightMode_edited.png'
import WorkoutViewDark from '../Assets/workoutViewDarkMode_edited.png'
import RestTimerView from  '../Assets/restTimerViewLight_edited.png'
import ExerciseInfoView from  '../Assets/exerciseInfoViewLight_edited.png'
import CreateExerciseView from  '../Assets/editExerciseViewLight_edited.png'
import DashboardView from  '../Assets/dashboardViewDark_edited.png'
import WorkoutBarChart from  '../Assets/workoutBarChartViewDark_edited.png'
import ExerciseLineChart from '../Assets/exerciseChartViewDark_edited.png'
import WidgetLarge from '../Assets/widgetLargeDark.png'
import WidgetMediumSmall from '../Assets/widgetsMediumSmall.png'

export class AppStoreMarketing extends Component {

    constructor(props) {
        super(props)

        this.state = {'screenshots': [
                [{caption: 'Log every workout in light mode...', align:'center', widthPercentage:'90%', columns:4, image:WorkoutViewLight},
                {caption: '... or in stunning dark mode', align:'center',  widthPercentage:'90%',  columns:4, image:WorkoutViewDark},
                {caption: 'Set a rest timer for each exercise', align:'center', widthPercentage:'90%',   columns:4, image:RestTimerView}], 
                [{caption: 'Select from built-in exercises...', align:'center', widthPercentage:'90%', columns:4, image:ExerciseInfoView},
                {caption: '... and create your own exercises!',align:'center', widthPercentage:'90%',  columns:4, image:CreateExerciseView},
                {caption: 'Keep track of workout metrics...', align:'center', widthPercentage:'90%',   columns:4, image:DashboardView}],
                [{caption: '... with workout charts...',align:'left', widthPercentage:'80%',   columns:12, image:WorkoutBarChart}],
                [{caption: '... and exercise charts', align:'left', widthPercentage:'80%',   columns:12, image:ExerciseLineChart}],
                [{caption: 'New: Workout Chart Widget', align:'left', widthPercentage:'90%',   columns:6, image:WidgetLarge},
                {caption: 'New: Metrics Widgets', align:'left', widthPercentage:'90%',   columns:6, image:WidgetMediumSmall}]
            ]
        }
    
    }

    

    render() {
        const grid = this.state.screenshots.map((row)=> {
            
            {return <Row style={{marginBottom: '15px', marginTop: '15px'}}> 
                {row.map((item)=> {
                    return <Col md={item.columns}>
                        <Figure>
                            <Figure.Image width={item.widthPercentage} src={item.image} rounded/>
                            <Figure.Caption style={{color: 'black', fontSize: '100%',  textAlign: item.align }}> {item.caption}</Figure.Caption>
                        </Figure>
                    </Col>
                })}
            </Row>}   
           
            
        })

        return <Container>

            <Row>

                <Col md={12}>
                    <a href="https://apps.apple.com/us/app/muscular/id1513704154?ign-mpt=uo%3D2" target="_blank">
                      <Image height={'80px'} style={{padding:'10px'}} src={AppStoreIcon}/>
                    </a>
                    <Image height={'60px'} src={AppIcon}/>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h2>Make every workout count! </h2>
                    <p> Easily log your workout progress - whether you work out in a gym, at home or outdoors. 
                        Try out the app without paying a single cent. When you are ready for more, pay once for life-time access! 
                        Without subscription only for a limited time!</p>

                    <p>
                    Why Muscular Workout Log could be just the workout app you are looking for:</p>
                     <ul>
                        <li>Over 100 exercises included + create your own exercises</li>
                        <li>Our app is gym-proof: do the exercises in any order you want. Replace, reorder on the go.</li>
                        <li>Tired of inflexible workout apps that force you to set the weight unit to either LB or KG for all exercises? With Muscular you can toggle the weight unit during workout for each exercise separately!</li>
                        <li>Easily compare last time's weights, reps and sets with current metrics.</li>
                        <li>The metric bar allows you to easily compare the current exercise metrics with your personal records</li>
                        <li>More precise workout volume calculation for bodyweight exercises: Each bodyweight exercise has a bodyweight percentage (e.g. you push about 64% of your bodyweight each standard push-up)</li>
                        <li>Bodyweight exercises with assisted mode enabled allow switching from weighted to assisted for each set individually!</li>
                        <li>Auto-adjusting rest timer: change the rest timer duration on the go for each exercise individually. Or set a fixed rest time.</li>
                        <li>Double set mode: Always keeps the set number even and the rest time only fires every second set. Great for single-side exercises like one-arm rows, cable shoulder raises etc. or for drop sets.</li>
                        <li>Duration exercises: Log the time instead of the weight (+reps if applicable). Featuring a built-in duration timer</li>
                        <li>Charts: Visually analyse your workout activity and follow your progress for specific exercises </li>
                        <li>New: Workout charts and metrics widgets</li>
                    </ul>
                    
                </Col>
            
            </Row>

            {grid}

        </Container>

    }



}


export default AppStoreMarketing