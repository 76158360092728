import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
// import config from './Config/firebaseConfig';
// import firebase from 'firebase/app';
//import 'firebase/storage';
//import Header from './Components/Header';

import HtmlReader from './Pages/HtmlReader';
import NoMatch from './Pages/NoMatch';

import MainLayout from './Components/MainLayout';
import NavigationBar from './Components/NavigationBar';
import Jumbotron  from './Components/Jumbotron';
import SupportForm from './Pages/SupportForm'
import AppStoreMarketing from './Pages/AppStoreMarketing.js'
import './App.css'

class App extends Component {
  

  constructor(props) {
 
    super(props)

    // firebase.initializeApp(config)
    // console.log(firebase.firestore())
    
  //   this.firebaseApp = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app()

  //  console.log(this.firebaseApp.firestore)
   
//firestore={firebase.firestore()} 
  }

  render() {
      return (
        <Fragment>
            <Router>
              <NavigationBar/>
              <Jumbotron/>
                <MainLayout>
                  <Switch>
                    <Route exact path='/' component={AppStoreMarketing} />
                    <Route path='/terms'  render={props => (
                    <HtmlReader filePath='./termsConditions.html' title='Terms & Conditions' />
                    )} />
                    <Route path='/privacy'  render={props => (
                    <HtmlReader filePath='./privacyPolicy.html' title='Privacy Policy' />
                    )} />
                    <Route path='/support' component={SupportForm}/>
                    <Route component={NoMatch}/>
                </Switch>
                </MainLayout>
            </Router>
          </Fragment>
      )
  }
}

export default App;

