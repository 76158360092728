import React, { Component } from 'react';
import PropTypes from 'prop-types';


export class HtmlReader extends Component {
    
    static propTypes = {
        filePath: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }

    abortController = new AbortController()


    constructor(props) {
        super(props)
        //console.log(props)
        this.state = {
            htmlContent: ''
        }

    }

    componentDidMount() {
        this.updateContent()

    }

    componentDidUpdate() {  // without calling this method, html reader component won't update when clicking terms or privacy after html reader already rendered!
        this.updateContent()
    }



    updateContent() {
        
        fetch(this.props.filePath, {signal: this.abortController.signal}) // abort controller aborts api call when component will be unmounted (e.g. user clicked other menu section!)
        .then(response => response.text())
        .then((html)=> {
            
           // console.log(html)

            var ReactDOMServer = require('react-dom/server');
            var HtmlToReactParser = require('html-to-react').Parser;    
            var htmlToReactParser = new HtmlToReactParser();
            var reactElement = htmlToReactParser.parse(html);
            var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
            // console.log(reactHtml)
            this.setState({htmlContent:reactHtml})
 
        })
        .catch((err)=> {  
            if (err.name === 'AbortError') return 
            console.log('Failed to fetch content: ', err);  
        });
    }

    componentWillUnmount() {
        this.abortController.abort()

    }


    render() {

        return (
            <div>
                <h2>{this.props.title}</h2>
                <div dangerouslySetInnerHTML={{__html: this.state.htmlContent}}>
                </div>
            </div>
        )
    }
}



export default HtmlReader;
