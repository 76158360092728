import React, { Component } from 'react';
import {Form, Button} from 'react-bootstrap';
import axios from 'axios';
import config from '../Config/firebaseConfig';
import RCG from 'react-captcha-generator';

class SupportForm extends Component {

    constructor(props){
        super(props)

        this.state = {
            senderName: '',
            email: '',
            subject: '',
            text: '',
            senderNameValid: false,
            emailValid: false,
            subjectValid: false,
            textValid: false,
            formValid: false,
            hideSuccessMessage: true,
            errorMessage: '',
            captcha: '',
            captchaEnter: '',
            captchaValid: false

        }

        this.result = this.result.bind(this)

    }

    render() {

        return (
            <React.Fragment>
                <Form validated={this.state.formValid} onSubmit={this.handleSubmit.bind(this)}>
                    <Form.Group controlId="supportForm.senderName">
                        <Form.Label>First name, last name (at least 4 characters)</Form.Label>
                        <Form.Control value={this.state.senderName} onChange={(event) => this.handleUserInput(event)} required type="text" name="senderName" placeholder="Jon Doe" />
                        <Form.Control.Feedback>Nice name!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="supportForm.email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control value={this.state.email} onChange={(event) => this.handleUserInput(event)} required type="email" name="email" placeholder="name@example.com" />
                        <Form.Control.Feedback>Looking good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="supportForm.subject">
                        <Form.Label>Subject (at least 6 characters)</Form.Label>
                        <Form.Control value={this.state.subject} onChange={(event) => this.handleUserInput(event)}  required type="text" name="subject" placeholder="Example subject" />
                        <Form.Control.Feedback>A subject is always useful...</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="supportForm.text">
                        <Form.Label>Type your support request here (at least 20 characters)</Form.Label>
                        <Form.Control value={this.state.text} onChange={(event) => this.handleUserInput(event)}  placeholder="Great app!"  name="text" required as="textarea" rows="6" />
                        <Form.Control.Feedback>A just question, my Liege!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="supportForm.captcha">
                        <RCG result={this.result} /> 
                        <Form.Control value={this.state.captchaEnter} onChange={(event) => this.handleUserInput(event)} required type="text" name="captchaEnter" placeholder="Enter the numbers / letters in the image" />
                        <Form.Control.Feedback>It seems you are human!</Form.Control.Feedback>
                    </Form.Group>
                    
                    <p className="errorMessage" hidden={this.state.errorMessage === ''}>{this.state.errorMessage}</p>
                    <p className="successMessage" hidden={this.state.hideSuccessMessage}>Your support request has been sent successfully!</p>
                    <Button className="mb-3" variant="primary" type="submit" disabled={!this.state.formValid}>
                        Submit
                    </Button>
                </Form>
            </React.Fragment>
        )

    }

    result(text) {
        this.setState({
          captcha: text
        })
    }

    handleUserInput(e) {
        const name = e.target.name;
        //console.log(name)
        const value = e.target.value;
        this.setState({[name]: value}, () => { this.validateField(name, value) });
        console.log(this.state)
        console.log(this.state.captchaEnter)

    }

    validateField(fieldName, value) {
      
        var senderNameValid = this.state.senderNameValid;
        var emailValid = this.state.emailValid;
        var subjectValid = this.state.subjectValid;
        var textValid = this.state.textValid;
        var captchaValid = this.state.captchaValid;

        switch(fieldName) {
          case 'senderName':
            senderNameValid = value.length >= 4;
            break;
          case 'email':
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            emailValid = re.test(String(value).toLowerCase())
            break;
          case 'subject':
            subjectValid = value.length >= 6; 
            break;
          case 'text':
            textValid = value.length >= 20;
            break;
          case 'captchaEnter':
            captchaValid = value !== '' && value === this.state.captcha
            break;
 
          default:
              // no default
            break;
        }
        this.setState({ 
                senderNameValid: senderNameValid,
                emailValid: emailValid,
                subjectValid: subjectValid,
                textValid: textValid,
                captchaValid:captchaValid

            }, this.validateForm);
      }

      validateForm() {
        this.setState({formValid: this.state.senderNameValid && this.state.emailValid && this.state.subjectValid && this.state.textValid && this.state.captchaValid});
      }

    handleSubmit(event) {
       // console.log('handle submit')
        event.preventDefault();
        
        if (this.state.formValid) {

            this.sendSupportRequest()
        }

    }

    async sendSupportRequest() {

        const body = {subject: this.state.subject, text: this.state.text, senderName: this.state.senderName, senderEmail: this.state.email}

        console.log(body)
        console.log(`url: ${config.supportUrl}`)
        axios.post(config.supportUrl, body)
        .then((response)=>{

            console.log(response);
            console.log(response.statusText)
            if (response.status === 200) {
                this.setState({
                    senderName: '',
                    email: '',
                    subject: '',
                    text: '',
                    senderNameValid: false,
                    emailValid: false,
                    subjectValid: false,
                    textValid: false,
                    formValid: false,
                    hideSuccessMessage: false,
                    errorMessage: '',
                    captcha: '',
                    captchaEnter: '',
                    captchaValid: false
                })

            } else {
           
                console.log(response.status)
                this.setState({errorMessage: `Something went wrong - server status code ${response.status}`})
            }
        })
        .catch((error)=> {
            console.log(error)
            this.setState({errorMessage: `Something went wrong: ${error}`})
        })
 
    }

}



export default SupportForm;
